
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useThemeContext } from "../../hooks/usethemeContext";
import { switchTheme } from "../../assets/styles";
// import DomainIframe from "../sub-components/banners/DomainIframe";
import { getTaxSoftwareText, dealType } from "./helpers/taxSoftware";
import { Container, Col, Row } from "react-bootstrap";
import TermsNConditions from "./TermsNConditions";
import { Card } from "react-bootstrap";
import Stripe from "./buttons/Stripe";


export default function Pricing() {
  const [data, setData] = useState(false);
  const [promo, setPromo] = useState(false);
  const [promoDate, setPromoDate] = useState('01 June 2024')
  const [firstDealDisabled, setfirstDealDisabled] = useState(true);
  const [secondDealDisabled, setsecondDealDisabled] = useState(true);
  const [thirdDealDisabled, setthirdDealDisabled] = useState(true);
  // const [termsNConditionsViewed, setTermsNConditionsViewed] = useState(true);
  const navigate = useNavigate();
  // define styles by darkMode
  const { darkMode } = useThemeContext();
  const { dealStyles, pricingBackgroundColor } = switchTheme(darkMode);
  const cardText = `list-unstyled mt-3 mb-4 ${darkMode ? "text-light" : "text-dark"}`;
  const sm = 1, lg = 3;
  const agree = `By Making Payment`
  const agree2 = `I Agree With The Service Contract`
  const agreeStyle = {fontSize:'15.5px'}
  const muted = {color:'#6c757d'}
  const pricing = promo ? "-promo" : "";

  function checkPromo() {
    const now = new Date();
    const promoEndTime = Date.parse(`${promoDate} 23:59:59 EST`);
    if (promo !== true && promoEndTime > now) {
      setPromo(true);
    }
    else if(promo === true && promoEndTime < now) {
      setPromo(false);
      setPromoDate('01 Jan 2023')
    }
  }

  // Stripe Prices
  const firstDealStripePrice = data ? data.first_deal_stripe_price : 'price_1PCllQGdRDWQxGXyunBsxqNt';
  const secondDealStripePrice = data ? data.second_deal_stripe_price : 'price_1PCli3GdRDWQxGXyTYBz39Ym';
  const thirdDealStripePrice = data ? data.third_deal_stripe_price : 'price_1PClSqGdRDWQxGXyKVC0nozE';


  // Promotions/Deals
  const firstDealDiscount = data ? data.first_deal_discount : 0;
  const secondDealDiscount = data ? data.second_deal_discount : 0;
  const thirdDealDiscount = data ? data.third_deal_discount : 0;
  const regularPrice = "Regular Price";
  const firstDealRegularPrice = data ? data.first_deal_regular_price  : 699;
  const secondDealRegularPrice = data ? data.second_deal_regular_price  : 2499;
  const thirdDealRegularPrice = data ? data.third_deal_regular_price  : 4999;
  
  const firstDealPromo = firstDealRegularPrice - firstDealDiscount;
  const secondDealPromo = secondDealRegularPrice - secondDealDiscount;
  const thirdDealPromo = thirdDealRegularPrice - thirdDealDiscount;
  
  const firstDeal = promo ? firstDealPromo : firstDealRegularPrice;
  const secondDeal = promo ? secondDealPromo : secondDealRegularPrice;
  const thirdDeal = promo ? thirdDealPromo : thirdDealRegularPrice;

  // const sale = <p style={muted}>Offer Expires {promoDate}</p>
  // const notOnSale = <p style={muted}>Not On Sale At This Time</p>
  // const fullPaymentHTML = <p style={muted}>Contact Us If Interested</p>
  // const textWarning = 'text-warning'

  const sale = <p style={muted}>Offer Expires {promoDate}</p>
  // const notOnSale = thirdDealDiscount === 0 || secondDealDiscount === 0 ? <p style={muted}>Not On Sale At This Time</p> : <p style={muted}>Offer Expires {promoDate}</p>
  const fullPaymentHTML = <p style={muted}>Contact Us If Interested</p>
  const textWarning = 'text-warning'

  // const test = data && data.first_deal_discount === 0 ? notOnSale : sale;
  // const test = firstDealDiscount === 0 ? notOnSale : sale;

  
  // Promotions/Deals
  const taxSoftwareText = getTaxSoftwareText( 
    // test,
    muted,
    promo, 
    promoDate,
    fullPaymentHTML, 
    sale, 
    regularPrice,
    firstDealDiscount,
    secondDealDiscount,
    thirdDealDiscount,
    firstDealRegularPrice, 
    secondDealRegularPrice, 
    thirdDealRegularPrice
  );

  const handleCheck = (choice) => {
    if(choice === 'firstDealDisabled'){
      setfirstDealDisabled(!firstDealDisabled);
      setsecondDealDisabled(true);
      setthirdDealDisabled(true);
      document.getElementById('secondDealTerms').checked = false;
      document.getElementById('thirdDealTerms').checked = false;
    } 
    else if(choice === 'secondDealDisabled'){
      setfirstDealDisabled(true);
      setsecondDealDisabled(!secondDealDisabled);
      setthirdDealDisabled(true);
      document.getElementById('firstDealTerms').checked = false;
      document.getElementById('thirdDealTerms').checked = false;
    } 
    else if(choice === 'thirdDealDisabled'){
      setfirstDealDisabled(true);
      setsecondDealDisabled(true);
      setthirdDealDisabled(!thirdDealDisabled);
      document.getElementById('firstDealTerms').checked = false;
      document.getElementById('secondDealTerms').checked = false;
    }
  }

  
  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      console.log(query.get("success"))
      navigate('/confirmation', { state: { status: 'success' }});
    }

    if (query.get("canceled")) {
      navigate('/confirmation', { state: { status: 'canceled' }});
    }
  });


  // Fetch data on mount
  useEffect(() => {
    if(data === false) (async function () {
      try {
        const response = await fetch(process.env.REACT_APP_API);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const res = await response.json();
        setData((currentState) => { return currentState = res });
        setPromoDate((currentState) => { return currentState = res.promo_date });
      } catch (error) {
        console.error('Caught error:', error);
      }
    }())
  }); // Empty dependency array means this runs once when the component mounts


  useEffect(() => {
    const intervalId = setInterval(checkPromo, 1000 * 1); // checks every second
    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, [promoDate]) // This runs when promoDate updates to referesh the component


  return <>
    <div className="pt-4 pb-5">
      <Container>
        <div className="text-center">
          <h2>Free Software Setup</h2>
          <h5>
            Software & Training in English & Spanish Available
          </h5>
          <h5>
            <strong>Free - How To Apply For an EFIN & EIN </strong> - I
            Personally Guide You Through <strong>YOUR </strong> Application
          </h5>
        </div>
        <Row
          className={`justify-items-center pricing`}
          xs={1}
          sm={sm}
          lg={lg}
        > 
          <Col>
            <Card
              className={`bootstrap-card${pricing} shadow ${pricingBackgroundColor} rounded text-center mt-2 mb-5`}
            >
              <Card.Header as={"h4"} style={dealStyles}>
                {dealType.firstDeal}
              </Card.Header>
              <Card.Body className={cardText}>
                <Card.Title as={"h1"} className={textWarning}>
                  {firstDeal} <small className={textWarning}>/ yr</small>
                </Card.Title>
                <Card.Text as={"h5"}>
                  <ul className="list-unstyled mt-3 mb-4">
                    {taxSoftwareText.firstDeal}
                  </ul>
                </Card.Text>
                <div className="d-grid position-sticky top-100">
                  <div>
                    {/* <span style={{fontSize:'15px'}}>Click to View Terms & Conditions</span> */}
                    <TermsNConditions type='TaxPro Incubator'/>
                    <br />
                    <input type="checkbox" id="firstDealTerms" name="firstDealTerms" onChange={() => handleCheck('firstDealDisabled')} />
                    &nbsp;
                    <label htmlFor='firstDealTerms' >{agree}</label>
                    <h5 style={agreeStyle}>{agree2}</h5>
                  </div>
                  <br />
                  <div>
                    <Stripe 
                      disabled={firstDealDisabled}
                      price={firstDealStripePrice}
                      id={'taxpro-incubator'}
                    />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card
              className={`bootstrap-card${pricing} shadow ${pricingBackgroundColor} rounded text-center mt-2 mb-5`}
            >
              <Card.Header as={"h4"} style={dealStyles}>
                {dealType.secondDeal}
              </Card.Header>
              <Card.Body className={cardText} >
                <Card.Title as={"h1"} className={textWarning}>
                  {secondDeal} <small className={textWarning}>/ yr</small>
                </Card.Title>
                <Card.Text as={"h5"}>
                  <ul className="list-unstyled mt-3 mb-4">
                    {taxSoftwareText.secondDeal}
                  </ul>
                </Card.Text>
                <div className="d-grid position-sticky top-100">
                  <div>
                    {/* <span style={{fontSize:'15px'}}>Click to View Terms & Conditions</span> */}
                    <TermsNConditions type='TaxPro Accelerator' />
                    <br />
                    <input type="checkbox" id="secondDealTerms" name="secondDealTerms" onChange={() => handleCheck('secondDealDisabled')} />
                    {/* <input disabled={termsNConditionsViewed} type="checkbox" id="secondDealTerms" name="secondDealTerms" onChange={() => handleCheck('secondDealDisabled')} /> */}
                    &nbsp;
                    <label htmlFor='secondDealTerms' >{agree}</label>
                    <h5 style={agreeStyle}>{agree2}</h5>
                  </div>
                  <br />
                  <div>
                    <Stripe 
                      // disabled
                      disabled={secondDealDisabled}
                      price={secondDealStripePrice}
                      id={'taxpro-accelerator'}            
                    />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card
              className={`bootstrap-card${pricing} shadow ${pricingBackgroundColor} rounded text-center mt-2 mb-5`}
            >
              <Card.Header as={"h4"} style={dealStyles}>
                {dealType.thirdDeal}
              </Card.Header>
              <Card.Body className={cardText} >
                <Card.Title as={"h1"} className={textWarning}>
                  {thirdDeal} <small className={textWarning}>/ yr</small>
                </Card.Title>
                <Card.Text as={"h5"}>
                  <ul className="list-unstyled mt-3 mb-4">
                    {taxSoftwareText.thirdDeal}
                  </ul>
                </Card.Text>
                <div className="d-grid position-sticky top-100">
                  <div>
                    <TermsNConditions type='Service Bureau' />
                    <br />
                    <input type="checkbox" id="thirdDealTerms" name="thirdDealTerms" onChange={() => handleCheck('thirdDealDisabled')}/>
                    &nbsp;
                    <label htmlFor='thirdDealTerms' >{agree}</label>
                    <h5 style={agreeStyle}>{agree2}</h5>
                  </div>
                    <br />
                  <div>
                    <Stripe 
                      disabled={thirdDealDisabled}
                      price={thirdDealStripePrice}
                      id={'service-bureau'}
                    />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  </>;
}

