
export const dealType = {
  firstDeal: 'TaxPro Incubator',
  secondDeal: 'TaxPro Accelerator',
  thirdDeal: 'Service Bureau Incubator',
};

export function getTaxSoftwareText(
  // test,
  muted,
  promo, 
  promoDate,
  fullPaymentHTML, 
  sale, 
  regularPrice,
  firstDealDiscount,
  secondDealDiscount,
  thirdDealDiscount,
  firstDealRegularPrice, 
  secondDealRegularPrice, 
  thirdDealRegularPrice
){
  const noDiscount = <p style={muted}>Not On Sale At This Time</p>;
  const discountExpiritaion = <p style={muted}>Offer Expires {promoDate}</p>;
  const financingText = <div>
    <div className="d-flex justify-content-center align-items-center">
      <div className="text-start">
        <li>Our Easy Check Out:</li>
        <li>- Buy now, pay later</li>
        <li>- Financing Available</li>
        <li>- As low as 0% APR</li>
      </div>
    </div>
    <div className="fs-6 text-warning">
      <li className="mt-2">Click Affirm or AfterPay</li>
      <li>at checkout for offers</li>
    </div>
  </div>

  return {
    firstDeal:
      <>
        {promo ? 
          <> <p>{`${regularPrice} $${firstDealRegularPrice}`}</p>
            {firstDealDiscount === 0 ? noDiscount : discountExpiritaion}
            {/* {test} */}
          </>
         : 
          <></>
        }
         <p><strong>Tax Company</strong> Cloud App</p>
        <p>Free Training Academy</p>
        <p>Branded Taxes To Go App</p>
        <p>Mentorship & Marketing Training</p>
        <p>Top Tier Tech Support</p>
        {financingText}
      </>
    ,
    secondDeal: 
      <>
        {promo ?
          <>
            <p>{`${regularPrice} $${secondDealRegularPrice}`}</p>
            {/* {sale} */}
            {secondDealDiscount === 0 ? noDiscount : discountExpiritaion}
          </>
         : 
          <></>
        }
        {/* <p><strong>Unlimited</strong> Number Of Users</p> */}
        <p>Same as TaxPro Incubator</p>
        <p><strong>Advanced</strong> Recruiting</p>
        <p>Website & Hosting Plan</p>
        <p>Stripe Checkout</p>
        <p>Jotfrom and Calendly</p>
        {financingText}
      </>
    ,
    thirdDeal: 
      <>
        {promo ? 
          <><p>{`${regularPrice} $${thirdDealRegularPrice}`}</p>
            {/* {sale} */}
            {thirdDealDiscount === 0 ? noDiscount : discountExpiritaion}
          </>
         : 
          <></>
        }
        <p>Same as TaxPro Accelerator</p>
        <p><strong>Software Reseller</strong> Cloud App </p>
        <p>Build Your Own <strong>Network</strong></p>
        <p><strong>Advanced</strong> Recruiting</p>
        <p><strong>Advanced</strong> Mentorship & Marketing</p>
        {financingText}
      </>
    ,
  };
}