
import { useThemeContext } from "../../hooks/usethemeContext";
import { switchTheme } from '../../assets/styles';
import { Container, Col, Row } from "react-bootstrap";


const FunFacts = () => {
	// define styles by darkMode
    const { darkMode } = useThemeContext();
    const { color, backgroundColor, funFactIconColor } = switchTheme(darkMode);
    const sm = 6, lg = 3, xs = 12;

	const FunFactsComponent = () => {
		return <Container className='d-block justify-items-center'>
			<Row className="mx-3 align-self-center">
				<Col xs={xs} sm={sm} lg={lg}>
					<div
						className='single-fact'
						style={{ color }}
					>
						<i className="bi bi-hourglass-split" style={funFactIconColor}></i>
						<h2 style={funFactIconColor}>
							9 <span>+</span>
						</h2>
						<p>Years Tax In Business</p>
					</div>
				</Col>
				<Col xs={xs} sm={sm} lg={lg}>
					<div
						className='single-fact'
						style={{ color }}
					>
						<i className="bi bi-people" style={funFactIconColor}></i>
						<h2 style={funFactIconColor}>
							225 <span>+</span>
						</h2>
						<p>Business Partners</p>
					</div>
				</Col>
				<Col xs={xs} sm={sm} lg={lg}>
					<div
						className='single-fact'
						style={{ color }}
					>
						<i className="bi bi-person-plus" style={funFactIconColor}></i>
						<h2 style={funFactIconColor}>
							7000 <span>+</span>
						</h2>
						<p>Clients served</p>
					</div>
				</Col>
				<Col xs={xs} sm={sm} lg={lg}>
					<div
						className='single-fact'
						style={{ color }}
					>
						<i className="bi bi-cash-coin" style={funFactIconColor}></i>
						<h2 style={funFactIconColor}>
							95 <span>%</span>
						</h2>
						<p>funding rate</p>
					</div>
				</Col>
			</Row>
		</Container>
	}

    return (
        <div className={`fun-fact-area ${backgroundColor} `}>
			<FunFactsComponent />
		</div>
    )
}

export default FunFacts;
