
import { Helmet } from 'react-helmet';
import CalendlyInline from "./sub-components/buttons/CalendlyInline";

const Contact = () => {
	return <>
		<div>
			<Helmet>
				<title>Contact Us</title>
				<meta name="description" content="Contact Us and make a live one-on-one appointment today to speak with us. No EFIN, no problem.  We walk you throught every step of the process to get your EFIN number." />
				<meta property="og:title" content="Contact Us" />
				<meta property="og:description" content=" and make a live one-on-one appointment today to speak with us. No EFIN, no problem.  We walk you throught every step of the process to get your EFIN number. We also teach you howe to market your business, hire employees, and how to use your reports to make intelligent business decisions" />
				<meta name="keywords" content="software, tax softwar, tax prepare, taxes to go, tax slayer, tax trainin, tax office accelerato, service bureau accelerator, b2b, software development, web development, website development, business analytics" />
			</Helmet>
		</div>
		<div className='justify-items-center'>
			<section>
				<div>
					<CalendlyInline />
				</div>
			</section>
		</div>
	</>
}
export default Contact