
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { termsNConditionsTaxProAccelerator } from './helpers/termsNConditionsTaxProAccelerator';
import { termsNConditionsTaxProIncubator } from "./helpers/termsNConditionsTaxProIncubator";
import { termsNConditionsServiceBureauIncubator } from "./helpers/termsNConditionsServiceBureauIncubator";

const TermsNConditions = (props) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const serviceAgreement = `Service Agreement`

    let termsNConditions = termsNConditionsTaxProIncubator

    if(props.type === 'Service Bureau') termsNConditions = termsNConditionsServiceBureauIncubator;
    else if(props.type === 'TaxPro Accelerator')  termsNConditions = termsNConditionsTaxProAccelerator;
    return <>
        <Button id='service-agreement' variant="outline-warning" onClick={handleShow}>
            {serviceAgreement}
        </Button>
        
        <Modal
            size='lg'
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {serviceAgreement}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body id='ModalBody'>

                <div id='service-contract' dangerouslySetInnerHTML={{ __html: termsNConditions }}> 
                </div>

            </Modal.Body>
            <Modal.Footer>
            <div className="position-relative position-relative-example">
                <Button variant="danger" class="position-absolute bottom-0 end-0" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="warning" class="position-absolute bottom-0 start-0"  onClick={handleClose}>
                    Go Back
                </Button>
            </div>
            </Modal.Footer>
        </Modal>

    </>;
}
 
export default TermsNConditions;