
import { useThemeContext } from "../../hooks/usethemeContext";
// import { switchTheme } from "../../assets/styles";
import { Container, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";


export default function CompTable() {
    // define styles by darkMode
    const { darkMode } = useThemeContext();
    // const style = { fontSize: '13px' };
    // const light = 'light';
    const dark = 'dark';
    // const { fontColor } = switchTheme(darkMode);

    return <>
        <Container className={`py-5 bg-${darkMode ? dark : dark}`}>
            <h1 className="text-center" >Compare Our Software Packages</h1>
            <Table responsive striped hover variant={darkMode ? 'success' : 'light'} className="border border-2 rounded">
                <thead className="border-light">
                    <tr>
                        <th></th>
                        <th>
                            <strong>TaxPro Incubator</strong>
                        </th>
                        <th>
                            <strong>TaxPro Accelerator</strong>
                        </th>
                        <th>
                            <strong>Service Bureau Incubator</strong>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">Basic Tax Training</th>
                        <td>
                            <FontAwesomeIcon icon={faCheck} className="text-success" />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} className="text-success" />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} className="text-success" />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">EFIN & EIN Application Walkthrough</th>
                        <td>
                            <FontAwesomeIcon icon={faCheck} className="text-success" />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} className="text-success" />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} className="text-success" />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Top Tier Tech Support</th>
                        <td>
                            <FontAwesomeIcon icon={faCheck} className="text-success" />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} className="text-success" />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} className="text-success" />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Unlimited E-File</th>
                        <td>
                            <FontAwesomeIcon icon={faCheck} className="text-success" />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} className="text-success" />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} className="text-success" />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Free Software Setup</th>
                        <td>
                            <FontAwesomeIcon icon={faCheck} className="text-success" />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} className="text-success" />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} className="text-success" />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Unlimited Number Of Users</th>
                        <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} className="text-success" />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} className="text-success" />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Taxes To Go App With Your Logo</th>
                        <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} className="text-success" />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} className="text-success" />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Mentorship & Marketing Training</th>
                        <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} className="text-success" />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} className="text-success" />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Become A Software Reseller</th>
                        <td>
                            <FontAwesomeIcon icon={faTimes} className="text-danger" />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faTimes} className="text-danger" />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} className="text-success" />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Advanced Mentorship, Marketing, & Recruiting</th>
                        <td>
                            <FontAwesomeIcon icon={faTimes} className="text-danger" />
                        </td>
                        <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} className="text-success" />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">E-Commerce w/ Stripe</th>
                        <td>
                            <FontAwesomeIcon icon={faTimes} className="text-danger" />
                        </td>
                        <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} className="text-success" />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Offer Product Financing w/ Stripe</th>
                        <td>
                            <FontAwesomeIcon icon={faTimes} className="text-danger" />
                        </td>
                        <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} className="text-success" />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Corporate Website Built With React.js</th>
                        <td>
                            <FontAwesomeIcon icon={faTimes} className="text-danger" />
                        </td>
                        <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} className="text-success" />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Corporate Email Accounts</th>
                        <td>
                            <FontAwesomeIcon icon={faTimes} className="text-danger" />
                        </td>
                        <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} className="text-success" />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Corporate Webhosting Plan</th>
                        <td>
                            <FontAwesomeIcon icon={faTimes} className="text-danger" />
                        </td>
                        <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} className="text-success" />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Domain Name & SSL Certificates - Assistance</th>
                        <td>
                            <FontAwesomeIcon icon={faTimes} className="text-danger" />
                        </td>
                        <td>
                            <span>We help you buy these</span>
                            <p>so that you own them, not us.</p>
                        </td>
                        <td>
                            <span>We help you buy these</span>
                            <p>so that you own them, not us.</p>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </Container>
    </>
}
