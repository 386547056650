
import { useEffect, useState } from 'react';

// to store the state to local storage
export default function usePersistedState(key, defaultValue) {

  const [state, setState] = useState(() => {
    // if(localStorage.getItem(key)) console.log(localStorage.getItem(key))
    return JSON.parse(localStorage.getItem(key)) || defaultValue;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));

    if(JSON.stringify(state) === 'true') {
      // document.getElementById('root').setAttribute('class', 'bg-dark text-light');
      // document.querySelector('body').setAttribute("class", "bg-dark");
    } else {
      // document.getElementById('root').setAttribute('class', 'bg-light text-dark');
      // document.querySelector('body').setAttribute("class", "bg-light");
    }
  }, [key, state]);

  return [state, setState];
}