
import { useThemeContext } from "../../hooks/usethemeContext";
import { switchTheme } from '../../assets/styles';
import { Helmet } from 'react-helmet';
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import jose from '../../assets/images/jose.webp'
import lucy from '../../assets/images/lucy.jpg'


const AboutUs = () => {
	// define style by darkMode
	const { darkMode } = useThemeContext();
	const { mainBannerTextColor } = switchTheme(darkMode);

	// const style = {color: '#0e65d7'};
	const sm = '12', lg = '6'; // xm = 1,
	const styles = { margin:  "auto"};
	const space = 3;

    return (
      <>
	  	<div>
            <Helmet>
                <title>About Us</title>
                <meta name="description" content="About Us for data we collect in the course of doing business." />
                <meta property="og:title" content="AAbout Us - Meet Our Team" />
                <meta property="og:description" content="Our track record speaks for itself. Our Team has a combined experience of over 20 years in Business with over 500 individual business partners and over 15K clients that we support. We're designed to Accelerate your Business to the next level in a much shorter time and we will teach you how to do that based on your current needs and business status. Partner with us and let us help you accelrate your business to the next level. We won't leave you to try and figure things out on your own." />
				<meta name="keywords" content="software, tax softwar , tax prepare , taxes to go, tax slayer, tax trainin , tax office accelerato , service bureau accelerator, b2b, software development, web development, website development, business analytic" />
            </Helmet>
        </div>
        <div className={`about-area mt-5 pb-${space}`}>
			<div className={`text-center pb-${space} border-bottom-0`}>
				<h1 className={mainBannerTextColor}>
					Who We Are!
				</h1>
			</div>
			<Container className justify-items-center justif >
				<Row className="justify-items-center">
					<Col sm={sm} lg={lg}>
						<Row>
							<div className={`pt-${space}`}>
								<h4 className={`text-center ${mainBannerTextColor}`}>
									Lucy
								</h4>
								<img
									className={`rounded-${space} d-block mb-${space}`}
									style={styles}
									src={lucy}
									alt="First slide"
								/>
								<p>
									Hi I'm Lucy and I'm happily married to my husband Jose and I have two beautiful children who are the reason for my drive and perseverance. I've been in the Tax industry for over 10yrs and have ran my own Tax Software Company for over 7yrs. I started doing taxes out of pure necessity. At the time I was in a controlling and financially abusive relationship that I wanted to leave desperately. I had two children at home, without my own income to support them, or a family member that I could go to for help. I worked at building my business for years before I finally broke free.
								</p>
								<p>
									I was raised in East Point, GA, and I graduated with a degree in Exercise Science from Georgia State, Atlanta. I had my sights on attending medical School but the lack of support and childcare derailed those plans. My first year in college I couldn't afford childcare so I was forced to take my daughter to class with me. I didn't complain, I studied hard, and got accepted into the Honors College for high achieving students after just 6 months. Due to my academic success, I received a scholarship for my daughter that helped with costs at the on campus daycare.
								</p>
								<p>
									Medical school was no longer a possibility due to the lack of support but I saw an opportunity to learn how to do taxes. I grinded it out and learned everything I could and started my business in the dining room of my adopted mother with 5 dollars in my pocket. Years later, when I'm training offices, I tell them "If you believe you deserve it, then go get it!". My children deserved a good life and I deserved to not be gaslit every day and broke!
								</p>
								<p>
									You can get tax software from dozens of providers but I guarantee none of these people are ME. I bring the experience, the patience and the results. I don't have to brag because the success stories speak for themselves.  We have a model that is unlike any other where you get a reliable business support system where YOU own your business outright.
								</p>
							</div>
						</Row>
					</Col>
					<Col sm={sm} lg={lg}>
						<Row>
									<div className={`pt-${space}`}>
									<h4 className={`text-center ${mainBannerTextColor}`}>
										Jose
									</h4>
								<img
									className={`rounded-${space} d-block mb-${space}`}
									style={styles}
									src={jose}
									alt="First slide"
								/>
								<p>
									Hi I'm Jose and I'm happily married to my beautiful wife, Lucy. I'm a retired Army Veteran born and raised in Ponce, PR. I came to the United States looking for better opportunities and joined the Army before I graduated high school. I served in the Army until I retired in 2011. But the government doesn't do much to prepare us for civilian life and many of us end up in despair. So, I was divorced and bankrupt in 2015 but my low point was when my bank of 20+ years told me that my money wasn't good enough for a $300 secured credit card.
								</p>
								<p>
									I moved to Atlanta in 2017 to be near my daughter and for new opportunities. I met Lucy in 2019, she was already in the Tax industry and I was still struggling with my Army transition, but she's the daughter of a Navy Veteran and she understood me in a way that I didn't understand myself. I was working on becoming a software developer so we started working together, combining our skill sets to create something new in the Tax Industry.
								</p>
								<p>
									It's time to take your business to the next level and learn how market your business and learn how to look at your data so that you can grow! Here you can have professional business email, a professional logo, a professional website, and a business support system where you can ask questions, get tech-support, and training.  Let's get you on the path you your next level!
								</p>
								<p>
									You can get tax software from dozens of providers, but you can't get a reliable business support system where YOU own your business. Many providers keep the website and your emails, etc, when you leave them. Here at TaxPro YOU own your domain and you can transfer your domain, emails, clients, etc. if you leave us. Tax Companies enjoy piece of mind since we use bank products so that we don't touch your prep fees. They are deposited straight to your account. This is why over 120+ business partners trust us!
								</p>
							</div>
						</Row>
					</Col>
						<Container className="text-center">
							<h5 className={`text-center ${mainBannerTextColor}`}>
								Our Commitment to You!
							</h5>
						<p>
							Our goal is to change people's lives by providing the best services and opportunities to Tax Companies and Tax Preparers. We do business fairly and we don't keep your hard earned money. We provide training and tech-support for all our clients because we believe that you deserve a real opportunity.
						</p>
					</Container>
					<NavLink
						to="/contact"
						className={`mt-${space} btn btn-outline-primary`}
					>
						Contact us
					</NavLink>
				</Row>
          	</Container>
        </div>
      </>
    );
};

export default AboutUs;
