
const currentYear = new Date().getFullYear() + 1;

export const termsNConditionsTaxProAccelerator = `This TaxPro Accelerator and Tax Company Cloud Software Service Agreement (“Agreement”) sets forth the terms and conditions under which Metaverse Group Inc, dba TaxPro Accelerator, ("TaxPro", "we", "us", and related pronouns) will provide tax slayer's software, and related services to _______________________ (“you”, “your”, and related pronouns) as provided below. <br /><br />

_____ Our Philosophy. We provide value not found anywhere else in the industry. We are not just your Tax Software provider. We are your trainer, consultant, IT department (for tax software issues), and your mentor. We provide this additional value at no initial cost to you. We are compensated for these services through the use of Bank Products. We believe that if we take care of you and teach you how to market and grow your business, then we will build a stronger business relationship with great mutual benefits. <br /><br />

_____ Relationship. We want to build a great business relationship with all our clients. Our legal business relationship is that of an independent contractor. We provide 1099s, and any other legally required documents, for all independent contractors. We are not financial advisors, nor your employer, agent, or partner, and we will determine the method, details and means of performing our services. <br /><br />

_____ Services. We are an Incubator/Accelerator - which means that we will train and mentor you to advance your business in a much shorter time than you would be able to on your own, as long as you comply with your part. <br /><br />

We will provide Tax Slayer Software, TaxPro's and Third-Party Tax Slayer Tech support, EFIN Class ($399 value as needed), Taxes To Go mobile app, and a Tax Company Master Class ($499 value) as part of your Tax Company Software Package Purchase. You also will have access to Basic Tax Training ($1499 value) through our Teacheables Academy Online Portal. <br /><br />

We have a three (3) strike rule for scheduled training, classes, and mentorship events as follows; You will forfeit your training slot permanently if you schedule the training (3 times) and fail to attend the event without at least a 2hr advanced notice of cancellation. <br /><br />

We are not responsible for auditing or otherwise verifying the data you submit for your clients, or the data submitted by anyone that you hire, etc. We will be your IT Department for all your business-related issues for the services we provide. We are not responsible for auditing or otherwise verifying the data you submit for your clients. <br /><br />

These free added value offers are contingent on you continuing the Service Agreement through October 15, ${currentYear}, and the use of Bank Products as written in the Bank Products section of this agreement. If you terminate this contract before the above date you will owe the dollar value of the free training you've received. We will also include any transaction fees, charge-back fees, and administrative recovery fees. For example, if you attend the EFIN Class and decide to cancel afterwards you will owe $399 for that training, plus the value of the software offer that you paid, plus any charge-back fees and recovery fees that you cause us to incur. Please see the refund policy. <br /><br />

We are a Software As A Service Company and we also provide Software Development (B2B for our direct clients). Your $2499 website package purchase gives you a license to use one of our proprietary website templates built with React.js. This is a Limited Use License. This license includes one (1), free website and up to 3pages built with React.js, which is a super-fast and light-weight professional Website Building Framework. TaxPro Accelerator's website is developed and updated with React.js. We will be your IT Department for all your tax business software and website related issues. We will create and manage your email accounts and website related integrations such as calendars and e-commerce applications. We will assist and train you in how to install the email accounts on your devices. <br /><br />

Disclaimer - You will need a domain name and security certificates, which are not included in this offer.We can make suggestions of domain name and security certificates providers and we will walk youthrough the process. You can expect to invest approximately $200 - $300 on those items depending onyour choices. We will build, configure, deploy, update, and maintain; the website as long as you remainour client, along with your email serves and email setup for your business and personal devices. We donot host or maintain websites that were not built by us, nor do we provide support to websites that arehosted outside of our servers. Our website templates are not for sale. <br /><br />

_____ Fees and Refunds. There are no refunds on software sales, technological access, or intellectual property such as; but not limited to, training. Transmission Fee - $33 per Bank Product ($30 Fee to TaxPro and $3 Fee to Tax Slayer), Service Bureau Fee - $65 per Bank Product, Technology fee - $18, Bank Fee between $34.95 to $54.95 depending on the bank product chosen. These fees will also apply to anyone that you hire, etc. This policy will be strictly enforced through software settings and/or by contacting your Processing Bank(s). Users are required to agree to all terms and conditions as part ofpurchasing through the website, and these were made available to you prior to finalizing the purchase. All sales are final and there are no refunds for “buyer's remorse”. <br /><br />

_____ Software Renewal Fee. Renewal of license is based on production numbers and bank product usage.  Renewal of license can be free if you meet both of the following conditions: 1) no less than 50 bank products transmitted and funded; 2) no less than 90% use of bank products. <br /><br />

_____ Bank Products. We provide value not found anywhere else in the industry for a very low price. For that reason, we strictly enforce the use of Bank Products and require at least 70% of all returns filed through you are with Bank Products. You hereby acknowledge that your operations will consist of no less than 70% of all transactions through Bank Products. You are responsible to enforce this condition with anyone that you hire, etc, and to terminate access to anyone who violates this condition in a timely manner. <br /><br />

Suspension or termination of accounts are not common at all. We understand that it is necessary to bypass the use of these products from time to time and the intent is not to keep anyone conducting business, but to prevent people from abusing the use of external or direct e-filing products to avoid using Bank Products. Failure to enforce this policy may result on termination of access to the Tax Software products. We reserve the right to make a judgment call to determine if you are abusing the use of external or direct e-filing products, and either suspend or terminate access, with or without warning. <br /><br />

_____ Client Responsibilities. It is your responsibility to provide all the verification information that we require for the timely and accurate creation of your software. Information such as your EFIN and tax ID are critical and we are not usually able to change those once they are entered into the software. You must be careful and make sure that you have the right information upfront. <br /><br />

You are responsible (as needed) to train, audit, approve, verify, etc, anyone that you hire or give access to the software. This includes, but is not limited to, all tax forms received from employers, financial institutions, and investment vehicles, as well as substantiation of all other income and expenses. We cannot and do not conduct investigations or audits of income and expenses. We rely on you to provide full disclosure of your income and expenses. You should retain all documents, canceled checks, receipts, and other data that form the basis of income and deductions. You have the final responsibility for the income tax returns and compliance requirements. <br /><br />

We require that you use Contracts with Terms and Conditions with anyone that you hire, or allow access to the software.  Failure to have legal agreements in place increases your liability.  You agree to hold us harmless and defend us if anything should arise, or relate to, not having legal agreements in place. <br /><br />

_____ Errors, Omissions, Misrepresentations, Fraud, Illegal Acts. We use professional judgment in resolving questions and our services does not include any procedures designed to discover defalcations or other irregularities, should any exist. You are responsible for reporting foreign activities. US taxpayers are required to report worldwide income (US and foreign sources). Penalties for failure to report foreign activities are severe. <br /><br />

All training provided to include but not limited to: mentorship, marketing, tax preparation, etc are based on current regulations, best practices, and our own opinions.  You are responsible to comply with legal requirements and to do your due diligence prior to offering your services or acquiring new services to support your business. We are not responsible for your business decisions. <br /><br />

Any recommendations provided to you may not be used or relied upon by any other parties. Disputes with the IRS regarding interpretation of the tax law, or any other dispute between you, your company, and other parties will not constitute an error or omission since you have been advised of this and that you should seek legal advice from an Attorney. <br /><br />

_____ No Legal Services. TAXPRO ACCELERATOR IS A SERVICE PROVIDER. NO ATTORNEY CLIENT RELATIONSHIP EXISTS FROM THE SERVICES PROVIDED BY US TO ANY OF OUR CLIENTS <br /><br />

_____ Examination of Returns. Your returns may be selected for review or examination by the relevant taxing authorities. Any proposed adjustments by the examining agent are subject to certain rights of appeal, which rights may be forfeited if you do not respond in a timely manner. We recommend that you seek legal advice from a Tax Attorney before turning your files to an investigator or giving any information. <br /><br />

_____ Confidentiality. We will not disclose any of your privileged information, unless required by law, without written instructions from you. We may need to disclose to 3rd parties (such as Tax Slayer) for normal and necessary business interactions. These 3rd parties are necessary for conducting business operations. <br /><br />

_____ Termination. You may terminate this Agreement at any time. In the event that you terminate this Agreement and if any amount is owed, you agree to pay in full at the date of termination. We also reserve the right to terminate this Agreement, for failure to provide accurate documentation within a timely manner after requested by us; inconsistencies between your verbal or written statements and the documentation provided, unless explained to our satisfaction. There are no refunds as per the policy. Any amount owed as per this agreement will be recovered through one or more of the following methods: bank, payment processor, Software Provider, or a collection agency. We may also use other methods not mentioned here. <br /><br />

_____ Indemnification. You agree to defend, indemnify, and hold us harmless, the company, and its directors, officers, employees, and agents from and against any and all third-party claims, demands, and liabilities, resulting from your business activities and actions. <br /><br />

_____ Extension of Contract Provisions. The provisions of this Contract (Agreement) are flow-down provisions. They apply and are incorporated, to anyone that you hire for tax preparation purposes, or anyone that you give access to the software, whether you have signed an agreement with them or not. <br /><br />

_____ I hereby acknowledge that I understand and that I agree with all terms and conditions provided herein. I agree that any disputes shall be settled through the State of Florida. <br /><br />

____________________ <br />	
Date: _______________ <br />
______________, _____ <br />
____________________ <br />

<br />	<br />	
____________________ <br />	
Date: _______________ <br />
Lucille Tollinchi, President <br />	
Metaverse Group Inc dba TaxPro Accelerator <br />	
` 
