
import { Helmet } from 'react-helmet';
import { useThemeContext } from "../hooks/usethemeContext";
import MainBanner from "./sub-components/MainBanner";
import Advantages from './sub-components/Advantages';
import Pricing from "./sub-components/TaxSoftware";
import CompTable from "./sub-components/CompTable";
import FunFacts from "./sub-components/FunFacts";


const BusinessOpportunities = () => {
  const { darkMode } = useThemeContext();
  // const light = 'light';
  const dark = 'dark';

  return <>
  <div>
    <Helmet>
        <title>Tax Software</title>
        <meta name="description" content="We provide Tax Software, Tax Slayer Training, Tax Training, Tax Updates, Mentorship, and Tech Support that's unrivaled in the industry. We will teach you how to use the software and how to read your reports so that you can make smart business decisions. We have live training sessions and a Training Academy hosted on Teachable for On Demand Training. No one else provides the individual mentorship that you need the way we do it. Tax Pro Accelerator is a Business 2 Business Software as a Service (B2B SaaS) Accelerator. We're designed to Accelerate your Business to the next level in a much shorter time. Our proprietary business analytics tools, marketing and recruiting strategies, and mentorship are unmatched in the industry." />
        <meta property="og:title" content="Tax Software - A leading provider of Software and Tax Applications" />
        <meta property="og:description" content="We provide Tax Software, Tax Slayer Training, Tax Training, Tax Updates, Mentorship, and Tech Support that's unrivaled in the industry. We will teach you how to use the software and how to read your reports so that you can make smart business decisions. We have live training sessions and a Training Academy hosted on Teachable for On Demand Training. No one else provides the individual mentorship that you need the way we do it. Tax Pro Accelerator is a Business 2 Business Software as a Service (B2B SaaS) Accelerator. We're designed to Accelerate your Business to the next level in a much shorter time. Our proprietary business analytics tools, marketing and recruiting strategies, and mentorship are unmatched in the industry." />
        <meta name="keywords" content="software, tax softwar , tax prepare , taxes to go, tax slayer, tax trainin , tax office accelerato , service bureau accelerator, b2b, software development, web development, website development, business analytics" />
        <meta name="keywords" content="software, tax softwar , tax prepare , taxes to go, tax slayer, tax trainin , tax office accelerato , service bureau accelerator, b2b, software development, web development, website development, business analytics" />
    </Helmet>
  </div>
      <div>
        <section>
          <MainBanner page='software' />
        </section>
        <section id='fun-facts'>
          <FunFacts />
        </section>
        <section>
          <Advantages page='software' />
        </section>
        <section id='comp-table' className={`bg-${darkMode ? dark : dark}`}>
          <br /><br /><br />
          <CompTable />
          <br /><br /><br />
        </section>
        <section>
          <Pricing />
        </section>
      </div>
  </>;
};

export default BusinessOpportunities;