
// eslint-disable-next-line no-unused-vars
import { useState, useEffect, useRef } from 'react';
import { InlineWidget } from 'react-calendly';
import { useThemeContext } from "../../../hooks/usethemeContext";

const CalendlyInline = () => {
    const [isChecked, setIsChecked] = useState(false);
    // define style by darkMode
    const { darkMode } = useThemeContext();
    const calendlyRef = useRef(null);

    useEffect(() => {
        console.log('Componenet Mounted')
    }, [isChecked]); // Empty dependency array means this useEffect runs once after the initial render

    const handleCheckboxChange = (e) => {
        setIsChecked((prev) => !prev);
    }

    return <>
        <div ref={calendlyRef} className="mt-5 mb-5">
            <InlineWidget
                iframeTitle="Calendly Scheduling Page"
                pageSettings={{
                    backgroundColor: darkMode ? `rgb(0, 30, 60)` : '#fafdff',
                    hideEventTypeDetails: false,
                    hideGdprBanner: false,
                    hideLandingPageDetails: false,
                    primaryColor: '00a2ff',
                    textColor: '4d5055'
                }}
                styles={{
                    height: '750px',
                }}
                url={process.env.REACT_APP_CALENDLY}
            />
        </div>
    </>;
};
    // return (
    //     <>
    //         <div 
    //             ref={calendlyRef}
    //             style={{ marginTop: '100px', marginBottom: '100px'}}
    //             className='container justify-content-center'
    //         >
    //             <div 
    //                 className='d-flex text-center justify-content-center'
    //             >
    //                 <label htmlFor="calendly-toggle" style={{ textAlign: 'center', marginBottom: '5px', display: 'block', width:'50%' }} className="mx-5">
    //                     By checking this box you agree to receive text messages from TaxPro Accelerator, you can reply STOP to opt-out at any time. Please click on the link to read our&nbsp;
    //                     <a
    //                         className="contact-link" 
    //                         href="https://taxproaccelerator.com/contact" 
    //                         target="_blank" rel='noreferrer'>
    //                             Privacy Policy
    //                     </a>
    //                 </label>
    //             </div>
    //             <div className='d-flex justify-content-center mb-3'>
    //                 <input
    //                     id="calendly-toggle"
    //                     type='checkbox'
    //                     checked={isChecked}
    //                     onChange={handleCheckboxChange}
    //                     // style={{ marginBottom: '20px' }}
    //                 />
    //             </div>
    //             <button
    //                 className={`${!isChecked ? 'pe-none' : 'pe-auto'} w-100 bg-transparent border-0`}
    //                 tabindex={!isChecked ? -1 : 0}
    //                 aria-disabled="true"

    //             >
    //                 <InlineWidget
    //                     iframeTitle="Calendly Scheduling Page"
    //                     pageSettings={{
    //                         backgroundColor: darkMode ? `rgb(0, 30, 60)` : '#fafdff',
    //                         hideEventTypeDetails: false,
    //                         hideGdprBanner: false,
    //                         hideLandingPageDetails: false,
    //                         primaryColor: '00a2ff',
    //                         textColor: '4d5055'
    //                     }}
    //                     styles={{
    //                         height: '750px',
    //                     }}
    //                     url={process.env.REACT_APP_CALENDLY}
    //                 />
    //             </button>
    //         </div>
    //     </>


export default CalendlyInline;


// // eslint-disable-next-line no-unused-vars
// import { useState, useEffect, useRef } from 'react';
// import { InlineWidget } from 'react-calendly';
// import { useThemeContext } from "../../../hooks/usethemeContext";

// const CalendlyInline = () => {
//     const[isChecked, setIsChecked] = useState(false);
//     // define style by darkMode
//     const { darkMode } = useThemeContext();
//     const calendlyRef = useRef(null);

//     useEffect(() => {
//     }, [isChecked]); // Empty dependency array means this useEffect runs once after the initial render

//     const handleCheckboxChange = (e) => {
//         setIsChecked(e.target.checked);
//     }

//     return (
//         <>
//             <div 
//                 ref={calendlyRef}
//                 className='container align-items-center'
//                 style={{marginTop: '100px', marginBottom: '100px'}}
//             >
//                 <div className='align-self-center'>
//                     <input
//                         type="checkbox"
//                         id="calendly-toggle"
//                         // style={{marginTop: '100px', marginBottom: '100px'}}
//                             checked={isChecked}
//                             onChange={handleCheckboxChange}
//                     />
//                     <label htmlFor="calendly-toggle" style={{textAlign: 'start', marginBottom: '20px', width:'50%' }}>
//                         By checking this box you agree to receive text messages from TaxPro Accelerator, you can reply STOP to opt-out at any time. Please click on the link to read our
//                         <a
//                             className="text-light" 
//                             href="https://taxproaccelerator.com/contact" 
//                             target="_blank" rel='noreferrer'>
//                                 Privacy Policy
//                         </a>
//                     </label>
//                 </div>
//                 <div type="button" disabled={true}>
//                     <InlineWidget
//                         disabled={true}
//                         iframeTitle="Calendly Scheduling Page"
//                         pageSettings={{
//                             backgroundColor: darkMode ? `rgb(0, 30, 60)` : '#fafdff',
//                             hideEventTypeDetails: false,
//                             hideGdprBanner: false,
//                             hideLandingPageDetails: false,
//                             primaryColor: '00a2ff',
//                             textColor: '4d5055'
//                         }}
//                         styles={{
//                             height: '750px',
//                         }}
//                         url={process.env.REACT_APP_CALENDLY}
//                     />
//                 </div>
//                 {/* {
//                     isChecked ?
//                         <InlineWidget
//                             // className="mt-5 mb-5"
//                             iframeTitle="Calendly Scheduling Page"
//                             pageSettings={{
//                                 backgroundColor: darkMode ? `rgb(0, 30, 60)` : '#fafdff',
//                                 hideEventTypeDetails: false,
//                                 hideGdprBanner: false,
//                                 hideLandingPageDetails: false,
//                                 primaryColor: '00a2ff',
//                                 textColor: '4d5055'
//                             }}
//                             styles={{
//                                 height: '750px',
//                             }}
//                             url={process.env.REACT_APP_CALENDLY}
//                         />
//                         : null

//                 } */}
//             </div>
//         </>
//     );
// };

// export default CalendlyInline;


// // // eslint-disable-next-line no-unused-vars
// // import { useEffect, useRef } from 'react';
// // import { InlineWidget } from 'react-calendly';
// // import { useThemeContext } from "../../../hooks/usethemeContext";


// // const CalendlyInline = () => {
// //     // define style by darkMode
// //     const { darkMode } = useThemeContext();
// //     const calendlyRef = useRef(null);

// //     // useEffect(() => {
// //     // }, []); // Empty dependency array means this useEffect runs once after the initial render
    

// //     return <>
// //         <div 
// //             ref={calendlyRef}
// //             className='align-items-center'
// //             style={{marginTop: '100px', marginBottom: '100px'}}
// //             >
// //             <input
// //                 type="checkbox"
// //                 id="calendly-toggle"
// //                 style={{marginTop: '100px', marginBottom: '100px'}}
// //                 //   checked={isChecked}
// //                 //   onChange={handleCheckboxChange}
// //             />
// //             <label htmlFor="calendly-toggle">
// //                 By checking this box you agree to receive text messages from TaxPro Accelerator, you can reply STOP to opt-out at any time. Please click on the link to read our
// //                 <a
// //                     className="text-light" 
// //                     href="https://taxproaccelerator.com/contact" 
// //                     target="_blank" rel='noreferrer'>
// //                         Privacy Policy
// //                 </a>
// //             </label>
// //             <InlineWidget
// //                 // className="mt-5 mb-5"
// //                 iframeTitle="Calendly Scheduling Page"
// //                 pageSettings={{
// //                     backgroundColor: darkMode ? `rgb(0, 30, 60)` : '#fafdff',
// //                     hideEventTypeDetails: false,
// //                     hideGdprBanner: false,
// //                     hideLandingPageDetails: false,
// //                     primaryColor: '00a2ff',
// //                     textColor: '4d5055'
// //                 }}
// //                 styles={{
// //                     height: '750px',
// //                 }}
// //                 url={process.env.REACT_APP_CALENDLY}
// //             />
// //         </div>
// //     </>;
// // };

// // export default CalendlyInline;