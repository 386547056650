
const styles = { textAlign: 'center' };

export const mainBannerText = {
    home: {
        heading1: 
            <div>
                <h2 style={styles}>Welcome To TaxPro Accelerator</h2>
            </div>,
    },
    taxPartner: {
        heading1: 
            <div>
                <h2 style={styles}>We'll Teach You How To Grow Your Tax Business</h2>
            </div>,
        heading2: 
            <div>
                <h3 style={styles}>Service Bureau Incubator</h3>
                <h3 style={styles}>TaxPro Incubator & Accelerator</h3>
                <h5 style={styles}>Your Six Figure Business Starts Here</h5>
            </div>
    },
    // taxClient: {
    //     heading1: 
    //         <div>
    //             <h2 style={styles}>Our Goal Is To Earn Your Trust</h2>
    //         </div>,
    // }
}
