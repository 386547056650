
// define style by darkMode
export function switchTheme(darkMode) {
    const sun = "rgb(211, 165, 47)";
    const altBlue = 'rgb(20, 104, 238)';
    const whiteSmoke = 'whitesmoke';
    const blue = 'rgb(41, 116, 237)';
    const btnLight = 'btn-light';
    const dark = blue;
    const light = '#d8e0e7'; // bootstrap $light
    const red = 'rgb(237, 71, 41)';
    const burgundy = '#530617';
    const warning = '#ffc107'; // bootstrap btn-warning color code
    const teal = '#95cdd0';
    const pinkish = '#b046d4';

    return {
        advantagesIconColor: { color: !darkMode ? whiteSmoke : altBlue, },
        bg: darkMode ? 'dark' : 'light',
        uploadButtonBG: darkMode ? 'info' : 'primary',
        borderTop: { borderTop: darkMode ? ` 1px solid ${light}` : ` 1px solid ${dark}` },
        buttonColors: {
            red: red,
            blue: blue,
            burgundy: burgundy,
            altBlue: altBlue,
            btnLight: btnLight,
            teal: teal,
            warning: warning,
            whiteSmoke: whiteSmoke,
            pinkish: pinkish
        },
        color: darkMode ? 'light' : 'light',
        backgroundColor: darkMode ? 'bg-success' : 'bg-info',
        dealStyles: { color: darkMode ? whiteSmoke : altBlue, },
        formLabelstyle: darkMode ? 'backgroundCcolor : bg-success' : 'backgroundColor: bg-body',
        funFactIconColor: { color: darkMode ? whiteSmoke : altBlue, },
        iconStyle: { fontSize: '49px', color: darkMode ? sun : blue, },
        mainBannerTextColor: darkMode ? 'text-light' : 'text-secondary',
        PayPalButtonsStyle: darkMode ? 'whiteSmoke' : 'black',
        pricingBackgroundColor: darkMode ? 'bg-success' : 'bg-body',
        shadow: darkMode ? "shadow" : "shadow-sm",
        text: darkMode ? 'text-light' : 'text-dark',
        theme: darkMode ? 'bg-dark' : 'bg-light',
        variant: darkMode ? 'dark' : 'light',
    };
};