
import { Helmet } from 'react-helmet';
import MainBanner from "./sub-components/MainBanner";
import Advantages from "./sub-components/Advantages";


export default function Home() {

  return <>
  <div>
    <Helmet>
      <title>TaxPro Accelerator</title>
      <meta name="description" content="Tax Pro Accelerator is a Business 2 Business Software as a Service (B2B SaaS) Accelerator. We're designed to Accelerate your Business to the next level in a much shorter time. Our proprietary business analytics tools, marketing and recruiting strategies, and mentorship are unmatched in the industry. Our approach for accelerating your growth is designed to automate your business processes so that you can focus on growing your business. We are a leading provider of Tax Software, Tax Training, Business Marketing & Sales Training, and Software Application Development." />
      <meta property="og:title" content="Tax Pro Accelerator - A leading provider of Software, Tax Applications, and Tax Training" />
      <meta property="og:description" content="Tax Pro Accelerator is a Business 2 Business Software as a Service (B2B SaaS) Accelerator. We're designed to Accelerate your Business to the next level in a much shorter time. Our proprietary business analytics tools, marketing and recruiting strategies, and mentorship are unmatched in the industry. Our approach for accelerating your growth is designed to automate your business processes so that you can focus on growing your business. We are a leading provider of Tax Software, Tax Training, Business Marketing & Sales Training, and Software Application Development." />
      <meta name="keywords" content="software, tax softwar , tax prepare , taxes to go, tax slayer, tax trainin , tax office accelerato , service bureau accelerator, b2b, software development, web development, website development, business analytics" />
    </Helmet>
  </div>
    <div>
      <section>
        <MainBanner page='software' />
      </section>
      <section>
        <Advantages page='software' />
      </section>
    </div>
  </>;
};