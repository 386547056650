
import { useLocation } from 'react-router-dom';
import { useThemeContext } from "../../hooks/usethemeContext";
import { switchTheme } from '../../assets/styles';
// import ThemeSwitch from '../sub-components/buttons/ThemeSwitch';
import { Nav, Navbar, Container } from 'react-bootstrap';
import Logo from '../../assets/images/logo.webp';


export default function NavBar() {

  // define button style by darkMode
  const { darkMode } = useThemeContext();
  const { variant, bg } = switchTheme(darkMode);

  const location = useLocation(); // Get current location


  return <>
    <div className='pb-3 mt-1'>
      <Navbar fixed="top" collapseOnSelect expand="lg" bg={bg} variant={variant}>
        <Container>
          <Navbar.Brand href="/">
            <img
              src={Logo}
              width={"75%"}
              // className="d-inline-block align-top"
              alt="Company Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav 
              variant="underline" 
              activeKey={location.pathname} 
              className="me-auto"
            >
              <Nav.Item>
                <Nav.Link href="/">Welcome</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={"/business-opportunities"} href="/business-opportunities">Business Opportunities</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={"/about"} href="/about">About Us</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={"/contact"} href="/contact">Contact Us</Nav.Link>
              </Nav.Item>
            </Nav>
            <Nav className="me-auto">
              <Nav.Link 
                className="text-warning"
                eventKey={3}
                href="https://tax-pro-accelerator.teachable.com/"
                target="_blank"
                rel='noreferrer'
                > Training Academy
              </Nav.Link>
            </Nav>
            {/* <Nav>
              <ThemeSwitch />
            </Nav> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  </>;
}
